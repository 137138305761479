<template>
  <v-card class="mt-7">
    <v-progress-linear :active="loading" :indeterminate="loading" absolute color="#FB8C00" />
    <v-card-actions class="ml-3">
      <v-avatar v-for="n in 5" :key="n" size="25" class="ml-n3">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2Flogo.png?alt=media&token=ea4addb9-d4e1-474e-a73d-1db632798b1f"
        />
      </v-avatar>
      <v-card-title style="word-break: normal" class="ml-n2">
        Мои муравьеды:
        <span style="color: #FB8C00" class="ml-3 "> {{ +anteaters }} </span>
      </v-card-title>
    </v-card-actions>
    <author-product-list />
    <v-card-title v-if="listStudiosUser.length">
      Мои студии:
    </v-card-title>
    <v-card-text v-if="listStudiosUser.length">
      <router-link
        v-for="(studioUser, index) in listStudiosUser"
        :key="index"
        tag="span"
        class="cursorPointer d-flex"
        :to="`/studios/home/${studioUser.id}`"
        >{{ studioUser.studioName }}</router-link
      >
    </v-card-text>
    <v-card-title v-if="listStudiosGroupUser.length">
      Мои студии в группе:
    </v-card-title>
    <v-card-text v-if="listStudiosGroupUser.length">
      <router-link
        v-for="(studioUser, index) in listStudiosGroupUser"
        :key="index"
        tag="span"
        class="cursorPointer d-flex"
        :to="`/studios/group/${studioUser.id}`"
        >{{ studioUser.studioName }}</router-link
      >
    </v-card-text>
    <v-card-title v-if="listCalendarsUser.length">
      Мои календари:
    </v-card-title>
    <v-card-text v-if="listCalendarsUser.length">
      <router-link
        v-for="(calendarUser, index) in listCalendarsUser"
        :key="index"
        tag="span"
        class="cursorPointer d-flex"
        :to="`/calendar/${calendarUser.id}`"
        >{{ calendarUser.studioName }}</router-link
      >
    </v-card-text>
    <v-card-title v-if="artTherapyListUser.length">
      Арт-терапия:
    </v-card-title>
    <v-card-text v-if="artTherapyListUser.length">
      <router-link
        v-for="(artTherapy, index) in artTherapyListUser"
        :key="index"
        tag="span"
        class="cursorPointer d-flex"
        :to="path(artTherapy.typeExercise, artTherapy.id)"
        >{{ artTherapy.studioName }}</router-link
      >
    </v-card-text>
    <v-card-actions v-if="presenceSubscriptions">
      <v-btn small text @click="portalSessionStripe">управление подписками</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AnteatersStudiosProfile',
  components: {
    AuthorProductList: () => import('@/components/Auth/AuthorProductList')
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      loading: state => state.shared.loading,
      listStudios: state => state.studios.listStudios,
      listStudiosGroup: state => state.studios.listStudiosGroup,
      listCalendars: state => state.studios.listCalendars,
      artTherapyList: state => state.artTherapy.artTherapyList
    }),
    anteaters() {
      return +this.user.anteaters || ''
    },
    listStudiosUser() {
      return this.getListPayments(this.listStudios).filter(
        item => item.secondType !== 'artTherapy' && item.secondType !== 'spaceDrawing'
      )
    },
    listStudiosGroupUser() {
      return this.getListPayments(this.listStudiosGroup)
    },
    listCalendarsUser() {
      return this.getListPayments(this.listCalendars).filter(
        item => item.secondType !== 'artTherapy' && item.secondType !== 'spaceDrawing'
      )
    },
    artTherapyListUser() {
      const localList = [
        ...this.artTherapyList,
        ...this.listStudios.filter(item => item.secondType === 'artTherapy'),
        ...this.listCalendars.filter(item => item.secondType === 'artTherapy')
      ]
      return this.getListPayments(localList)
    },
    presenceSubscriptions() {
      if (this.user.payments) {
        return Object.values(this.user.payments).some(item => item.split('_').includes('sub'))
      }
      return false
    }
  },
  created() {
    if (!this.listStudios.length) this.$store.dispatch('getListStudios', 'studios')
    if (!this.listStudiosGroup.length) this.$store.dispatch('getListStudios', 'studiosGroup')
    if (!this.listCalendars.length) this.$store.dispatch('getListStudios', 'calendars')
    if (!this.artTherapyList.length) this.$store.dispatch('getArtTherapy')
  },
  methods: {
    path(typeExercise, id) {
      if (typeExercise === 'studios') {
        return `/studios/home/${id}`
      } else if (typeExercise === 'calendars') {
        return `/calendar/${id}`
      } else {
        return `/artTherapyDescription/${id}`
      }
    },
    portalSessionStripe() {
      this.$store.dispatch('portalSessionStripe')
    },
    getListPayments(list) {
      let localList = []
      if (this.user.payments) {
        Object.keys(this.user.payments).forEach(id => {
          if (this.user.payments[id]) {
            let result = list.find(elem => elem.id === id)
            if (result && !result.child) {
              localList.push(result)
            }
          }
        })
      }
      return localList
    }
  }
}
</script>

<style scoped lang="stylus">
.cursorPointer:hover{
  color #FB8C00
}
</style>
